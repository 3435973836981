import React, { useState, useEffect } from "react"
import { ToastContainer, toast } from "react-toastify"
import axios from "axios"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Container,
  Form,
} from "reactstrap"

function ProductCreate() {
  const [cat, setCat] = useState([])
  const [previewImage, setPreviewImage] = useState("")
  const [offerprice, setOfferprice] = useState(0)
  const [product, setProduct] = useState({
    title: "",
    desc: "",
    price: "",
    offerprice: "",
    percentage: "",
    sku: "",
    category: [],
    size: "",
    color: "",
    stock: "",
    image: [],
    imagePreviewUrls: [],
  })
  let name, value
  const handleInputs = e => {
    console.log(e)
    name = e.target.name
    value = e.target.value
    setProduct({ ...product, [name]: value })
  }
  const PostData = async e => {
    e.preventDefault()
    const formData = new FormData()
    formData.append("title", product.title)
    formData.append("desc", product.desc)
    formData.append("price", product.price)
    formData.append("offerprice", offerprice)
    formData.append("percentage", product.percentage)
    formData.append("sku", product.sku)
    formData.append("category", product.category)
    formData.append("size", product.size)
    formData.append("color", product.color)
    formData.append("stock", product.stock)
    for (let i = 0; i < product.image.length; i++) {
      formData.append("image", product.image[i])
    }

    axios
      .post(
        process.env.REACT_APP_URL + "/api/kaleproduct/productdata",
        formData,
        {}
      )
      .then(res => {
        console.log(res)
        if (res.status === 422 || !res) {
          window.alert("error")
        } else {
          window.alert("successfully added")
          window.location.reload(false)
        }
      })
  }

  //list of category
  const getCategorydata = async e => {
    const res = await fetch(
      process.env.REACT_APP_URL + "/api/catego/categorydatalist",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    const data = await res.json()
    console.log(data)
    if (res.status === 404 || !data) {
      alert("error")
    } else {
      setCat(data)
      console.log("get category data")
    }
  }

  const onFileChange = e => {
    let name = e.target.name
    let files = e.target.files

    let updatedImages = [...product.image]
    let updatedPreviewUrls = [...product.imagePreviewUrls]

    for (let i = 0; i < files.length; i++) {
      let file = new File([files[i]], files[i].name, { type: files[i].type })
      let reader = new FileReader()
      reader.onloadend = () => {
        updatedImages.push(file)
        updatedPreviewUrls.push(reader.result)
        if (updatedImages.length === files.length) {
          setProduct({
            ...product,
            [name]: updatedImages,
            imagePreviewUrls: updatedPreviewUrls,
          })
        }
      }
      reader.readAsDataURL(files[i])
    }
  }

  useEffect(() => {
    getCategorydata()
    const { price, percentage } = product
    const per = (price * percentage) / 100
    let actualamnt = price - per
    setOfferprice(actualamnt)
  }, [product])

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="page-title-box">
          <h4
            className="font-size-18 text-dark"
            style={{ padding: "0px 15px" }}
          >
            Add Product
          </h4>
        </div>
        <Container fluid={true}>
          <Row>
            <Col md="8"> </Col>
            <Col md="4">
              {" "}
              <div className="float-end d-none d-md-block mb-2">
                <Link
                  to={"/product"}
                  color="primary"
                  className="btn btn-dark dropdown-toggle waves-effect waves-light"
                >
                  <i className="mdi mdi-keyboard-backspace me-2"></i> Back
                </Link>
              </div>
            </Col>
          </Row>
          <Form>
            <Row>
              <Col>
                <Card>
                  <CardBody style={{ padding: "30px 100px" }}>
                    <Row className="mb-3">
                      <label className="mb-1 col-form-label text-dark">
                        Title
                      </label>
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control"
                          name="title"
                          value={product.title}
                          onChange={handleInputs}
                          required
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="mb-1 col-form-label text-dark">
                        Description
                      </label>
                      <div className="mb-3">
                        <textarea
                          type="text"
                          className="form-control "
                          rows={8}
                          name="desc"
                          value={product.desc}
                          onChange={handleInputs}
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="mb-1 col-form-label text-dark">
                        Price
                      </label>
                      <div className="mb-3">
                        <input
                          type="number"
                          className="form-control "
                          placeholder="price"
                          name="price"
                          value={product.price}
                          onChange={handleInputs}
                          required
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="mb-1 col-form-label text-dark">
                        Offer price
                      </label>
                      <div className="mb-3">
                        <input
                          type="number"
                          className="form-control "
                          placeholder="offerprice"
                          name="offerprice"
                          value={offerprice}
                          onChange={handleInputs}
                          required
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="mb-1 col-form-label text-dark">
                        Offer Percentage
                      </label>
                      <div className="mb-3">
                        <input
                          type="number"
                          className="form-control "
                          placeholder="percentage"
                          name="percentage"
                          value={product.percentage}
                          onChange={handleInputs}
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="mb-1 col-form-label text-dark">
                        Sku
                      </label>
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control "
                          placeholder="sku"
                          name="sku"
                          value={product.sku}
                          onChange={handleInputs}
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="mb-1 col-form-label text-dark">
                        Category
                      </label>
                      <div className="mb-3">
                        <select
                          name="category"
                          value={product.category}
                          onChange={handleInputs}
                          className="form-control "
                          required
                        >
                          <option value="" disabled>
                            choose category
                          </option>
                          {cat.map(element => {
                            return (
                              <>
                                <option value={element._id}>
                                  {element.title}
                                </option>
                              </>
                            )
                          })}
                        </select>
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="mb-1 col-form-label text-dark">
                        Product Size
                      </label>
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control "
                          placeholder="size"
                          name="size"
                          value={product.size}
                          onChange={handleInputs}
                          required
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="mb-1 col-form-label text-dark">
                        Color
                      </label>
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control "
                          placeholder="color"
                          name="color"
                          value={product.color}
                          onChange={handleInputs}
                        />
                      </div>
                    </Row>{" "}
                    <Row className="mb-3">
                      <label className="mb-1 col-form-label text-dark">
                        Product stock
                      </label>
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control "
                          placeholder="stock"
                          name="stock"
                          value={product.stock}
                          onChange={handleInputs}
                          required
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="mb-1 col-form-label text-dark">
                        Choose Images:
                      </label>
                      <div className="mb-3">
                        <input
                          type="file"
                          className="form-control "
                          name="image"
                          onChange={onFileChange}
                          multiple
                          accept="image/*"
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      {" "}
                      <div className="image-preview">
                        {product.imagePreviewUrls &&
                          product.imagePreviewUrls.map(url => (
                            <img
                              src={url}
                              alt="preview"
                              key={url}
                              height={"100px"}
                              width="150px"
                              style={{ margin: "10px" }}
                            />
                          ))}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <div className="text-center">
                        <button
                          type="submit"
                          className="btn btn-dark mb-3"
                          onClick={PostData}
                        >
                          Submit
                        </button>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ProductCreate
