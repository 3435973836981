import React, { useState, useEffect } from "react"
import { useNavigate, useParams, Link } from "react-router-dom"
import moment from "moment-timezone"
import { MDBDataTable } from "mdbreact"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Table,
} from "reactstrap"
import { ToastContainer, toast } from "react-toastify"
import Swal from "sweetalert2"

import "./Tables/datatables.scss"

function Contact() {
  const [tableData, setTableData] = useState([])

  const getEnquirydata = async e => {
    const res = await fetch(
      process.env.REACT_APP_URL + "/api/contactus/enquirydetails",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    const data = await res.json()
    console.log(data)
    if (res.status === 201) {
      setTableData(data)
      console.log("get enquiry data")
    } else {
      toast.error("error in fetching enquiry details")
      console.log("error in fetching enquiry details")
    }
  }
  useEffect(() => {
    getEnquirydata()
  }, [])

  const data = {
    columns: [
      {
        label: "name",
        field: "name",
        sort: "asc",
      },
      {
        label: "email",
        field: "email",
        sort: "asc",
      },
      {
        label: "Subject",
        field: "subject",
        sort: "asc",
      },
      {
        label: "Message",
        field: "message",
      },
    ],
    rows: tableData.map(con => ({
      name: con?.name,
      email: con?.email,
      subject: con?.subject,
      message: con?.message,
    })),
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="page-title-box">
            <h4 className="font-size-18"> Enquiry List</h4>
          </div>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {/* <MDBDataTable responsive bordered data={data} hover /> */}
                  <div className="table-responsive  mb-5">
                    <Table className="table table-bordered mb-0">
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Email</th>{" "}
                          <th scope="col">Subject</th>
                          <th scope="col">Message</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData?.map((con, index) => (
                          <tr key={index}>
                            <td>{con?.name}</td>
                            <td>{con?.email}</td>
                            <td>{con?.subject}</td>
                            <td>{con?.message}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Contact
