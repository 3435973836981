import React, { useState, useEffect } from "react"
import { ToastContainer, toast } from "react-toastify"
import axios from "axios"
import { useParams, Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Container,
  Form,
} from "reactstrap"

function ProductEdit() {
  const params = useParams()
  const [cat, setCat] = useState([])
  const [productinfo, setProductinfo] = useState({})
  const [product, setProduct] = useState({
    title: "",
    desc: "",
    price: "",
    offerprice: "",
    percentage: "",
    sku: "",
    category: "",
    size: "",
    color: "",
    stock: "",
    image: [],
  })

  const handleInputs = e => {
    console.log(e)
    let name = e.target.name
    let value = e.target.value
    setProduct({ ...product, [name]: value })
  }

  //list of category
  const getCategorydata = async e => {
    const res = await fetch(
      process.env.REACT_APP_URL + "/api/catego/categorydatalist",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    const data = await res.json()
    console.log(data)
    if (res.status === 404 || !data) {
      alert("error")
    } else {
      setCat(data)
      console.log("get category data")
    }
  }

  const getproductinfo = async id => {
    const res = await fetch(
      process.env.REACT_APP_URL + `/api/kaleproduct/productdata/${params.id}`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    )
    const data = await res.json()
    console.log(data)

    if (res.status === 404 || !data) {
      alert("error")
    } else {
      setProductinfo(data)

      // Convert image URL(s) to File object(s)
      const imageURLs = Array.isArray(data.image) ? data.image : [data.image] // Ensure image is always an array
      const filesPromises = imageURLs.map(async imageURL => {
        const response = await fetch(process.env.REACT_APP_URL + imageURL)
        const blob = await response.blob()
        return new File([blob], "product-image")
      })
      const files = await Promise.all(filesPromises)

      setProduct(prevProduct => ({
        ...prevProduct,
        title: data.title,
        desc: data.desc,
        price: data.price,
        offerprice: data.offerprice,
        percentage: data.percentage,
        sku: data.sku,
        category: data.category,
        size: data.size,
        color: data.color,
        stock: data.stock,
        // Replace existing images with new files
        image: files,
      }))

      // Set image previews
      const imagePreviews = files.map(file => URL.createObjectURL(file))
      setProduct(prevProduct => ({
        ...prevProduct,
        imagePreview: imagePreviews,
      }))
    }
  }

  const PostData = async e => {
    e.preventDefault()
    const formData = new FormData()
    formData.append("title", product.title)
    formData.append("desc", product.desc)
    formData.append("price", product.price)
    formData.append("offerprice", product.offerprice)
    formData.append("percentage", product.percentage)
    formData.append("sku", product.sku)
    formData.append("category", product.category)
    formData.append("size", product.size)
    formData.append("color", product.color)
    formData.append("stock", product.stock)

    // Check if new images are selected
    if (product.image.length > 0) {
      for (let i = 0; i < product.image.length; i++) {
        formData.append("image", product.image[i])
      }
    }

    axios
      .patch(
        process.env.REACT_APP_URL + `/api/kaleproduct/editproduct/${params.id}`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      )
      .then(res => {
        console.log(res)
        if (res.status === 422 || !res) {
          window.alert("error")
        } else {
          alert("successfully Updated")
        }
      })
  }

  const onFileChange = e => {
    let name = e.target.name
    let files = e.target.files

    // create an array of image previews
    let previewImages = []
    for (let i = 0; i < files.length; i++) {
      let reader = new FileReader()
      reader.onload = () => {
        previewImages.push(reader.result)
        // update the state with the array of image previews
        setProduct({ ...product, [name]: files, imagePreview: previewImages })
      }
      reader.readAsDataURL(files[i])
    }
  }
  useEffect(() => {
    getproductinfo()
    getCategorydata()
  }, [])
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="page-title-box">
          <h4
            className="font-size-18 text-dark"
            style={{ padding: "0px 15px" }}
          >
            Add Product
          </h4>
        </div>
        <Container fluid={true}>
          <Row>
            <Col md="8"> </Col>
            <Col md="4">
              {" "}
              <div className="float-end d-none d-md-block mb-2">
                <Link
                  to={"/product"}
                  color="primary"
                  className="btn btn-dark dropdown-toggle waves-effect waves-light"
                >
                  <i className="mdi mdi-keyboard-backspace me-2"></i> Back
                </Link>
              </div>
            </Col>
          </Row>
          <Form>
            <Row>
              <Col>
                <Card>
                  <CardBody style={{ padding: "30px 100px" }}>
                    <Row className="mb-3">
                      <label className="mb-1 col-form-label text-dark">
                        Title
                      </label>
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control"
                          name="title"
                          value={product.title}
                          onChange={handleInputs}
                          required
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="mb-1 col-form-label text-dark">
                        Description
                      </label>
                      <div className="mb-3">
                        <textarea
                          type="text"
                          className="form-control "
                          rows={8}
                          name="desc"
                          value={product.desc}
                          onChange={handleInputs}
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="mb-1 col-form-label text-dark">
                        Price
                      </label>
                      <div className="mb-3">
                        <input
                          type="number"
                          className="form-control "
                          placeholder="price"
                          name="price"
                          value={product.price}
                          onChange={handleInputs}
                          required
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="mb-1 col-form-label text-dark">
                        Offer price
                      </label>
                      <div className="mb-3">
                        <input
                          type="number"
                          className="form-control "
                          placeholder="offerprice"
                          name="offerprice"
                          value={product.offerprice}
                          onChange={handleInputs}
                          required
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="mb-1 col-form-label text-dark">
                        Offer Percentage
                      </label>
                      <div className="mb-3">
                        <input
                          type="number"
                          className="form-control "
                          placeholder="percentage"
                          name="percentage"
                          value={product.percentage}
                          onChange={handleInputs}
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="mb-1 col-form-label text-dark">
                        Sku
                      </label>
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control "
                          placeholder="sku"
                          name="sku"
                          value={product.sku}
                          onChange={handleInputs}
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="mb-1 col-form-label text-dark">
                        Category
                      </label>
                      <div className="mb-3">
                        <select
                          name="category"
                          value={product.category}
                          onChange={handleInputs}
                          className="form-control "
                          required
                        >
                          <option value="" disabled>
                            choose category
                          </option>
                          {cat.map(element => {
                            return (
                              <>
                                <option value={element._id}>
                                  {element.title}
                                </option>
                              </>
                            )
                          })}
                        </select>
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="mb-1 col-form-label text-dark">
                        Product Size
                      </label>
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control "
                          placeholder="size"
                          name="size"
                          value={product.size}
                          onChange={handleInputs}
                          required
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="mb-1 col-form-label text-dark">
                        Color
                      </label>
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control "
                          placeholder="color"
                          name="color"
                          value={product.color}
                          onChange={handleInputs}
                        />
                      </div>
                    </Row>{" "}
                    <Row className="mb-3">
                      <label className="mb-1 col-form-label text-dark">
                        Product stock
                      </label>
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control "
                          placeholder="stock"
                          name="stock"
                          value={product.stock}
                          onChange={handleInputs}
                          required
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="mb-1 col-form-label text-dark">
                        Images :
                      </label>
                      <div className="image-preview ">
                        {product.imagePreview &&
                          product.imagePreview.map((preview, index) => (
                            <img
                              key={index}
                              src={preview}
                              alt={`Preview ${index}`}
                              height={"100px"}
                              width="150px"
                              style={{ margin: "10px" }}
                            />
                          ))}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      {" "}
                      <div className="mb-3">
                        <input
                          type="file"
                          className="form-control "
                          name="image"
                          onChange={onFileChange}
                          multiple
                          accept="image/*"
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <div className="text-center">
                        <button
                          type="submit"
                          className="btn btn-dark mb-3"
                          onClick={PostData}
                        >
                          Submit
                        </button>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ProductEdit
