import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import Cookies from "js-cookie"
import { toast } from "react-toastify"

import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  Label,
  Form,
  FormFeedback,
  Input,
} from "reactstrap"

// import images
import logoSm from "../../assets/images/logo-sm.png"
import logo from "../../assets/kalesImages/kales_logo_white.png"

function Login() {
  let navigate = useNavigate()

  const [signin, setSignin] = useState({
    email: "",
    password: "",
  })

  let name, value
  const handleInputs = e => {
    console.log(e)
    name = e.target.name
    value = e.target.value
    setSignin({ ...signin, [name]: value })
  }

  const PostData = async e => {
    e.preventDefault()
    const { email, password } = signin
    const res = await fetch(
      process.env.REACT_APP_URL + "/api/auth/adminlogin",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          password,
        }),
      }
    )
    const data = await res.json()
    if (res.status === 422 || !data) {
      toast.error("Invalid data")
    } else if (res.status === 201) {
      Cookies.set("Userid", data.Userid)
      Cookies.set("value", data.Token_value)
      toast.success("Successfully logged in")
      setTimeout(() => {
        navigate("/")
      }, 3000)

      console.log(data)
    }
  }

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={4}>
              <Card className="overflow-hidden">
                <div className="bg-dark">
                  <div className="text-primary text-center p-4">
                    {" "}
                    <Link to="/">
                      {/* className="logo logo-admin" */}
                      <img src={logo} height="110" alt="logo" />
                    </Link>
                  </div>
                </div>

                <CardBody className="p-4">
                  <div className="p-3">
                    <Form className="mt-4">
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="username">
                          Username
                        </Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          id="email"
                          onChange={handleInputs}
                        />
                      </div>

                      <div className="mb-3">
                        <Label className="form-label" htmlFor="userpassword">
                          Password
                        </Label>
                        <Input
                          name="password"
                          type="password"
                          className="form-control"
                          placeholder="Enter Password"
                          onChange={handleInputs}
                        />
                      </div>

                      <div className="mb-3 row">
                        <div className="col-sm-6">
                          <div className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customControlInline"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customControlInline"
                            >
                              Remember me
                            </label>
                          </div>
                        </div>
                        <div className="col-sm-6 text-end">
                          <button
                            className="btn btn-dark w-md waves-effect waves-light"
                            type="submit"
                            onClick={PostData}
                          >
                            Log In
                          </button>
                        </div>
                      </div>

                      <div className="mt-2 mb-0 row">
                        <div className="col-12 mt-4">
                          <Link to="/forgot-password">
                            <i className="mdi mdi-lock"></i> Forgot your
                            password?
                          </Link>
                        </div>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Don&#39;t have an account ?{" "}
                  <Link to="/register" className="fw-medium text-primary">
                    {" "}
                    Signup now{" "}
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} Kingofpub. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by
                  www.brandingsparrow.com
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Login
