import Cookies from "js-cookie"
import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"

function Logout() {
  let navigate = useNavigate()
  Cookies.remove("value")
  Cookies.remove("Userid")
  useEffect(() => {
    Cookies.remove("value")
    Cookies.remove("Userid")
    const res = fetch(process.env.REACT_APP_URL + "/api/users/auth/logout", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
      .then(() => {
        if (res.status === 404) {
          toast.error("Something went wrong")
        }
      })
      .catch(err => {
        console.log(err)
      })
    Cookies.remove("value")
    Cookies.remove("Userid")
    toast.success("Successfully logged out")
    setTimeout(() => {
      navigate("/login")
      // window.location.reload()
    }, 3000)
  })
  return <></>
}

export default Logout
